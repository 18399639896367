import { createApi } from '@reduxjs/toolkit/query/react';
import { IProcedureList } from '../../../interfaces/procedure';
import { IApiResponse, IPaginate } from '../../../interfaces/common';
import { baseQuery } from '../common';
import { IDiagnosisSearch } from '../../../interfaces/diagnosis';

export const procedureApi = createApi({
	reducerPath: 'procedureList',
	baseQuery: baseQuery,
	tagTypes: ['ProcedureList', 'ProcedureList'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		uploadProcedureItemsFile: builder.mutation<IApiResponse<IProcedureList[]>, FormData>({
			query: (body) => ({
				url: `/procedure/upload`,
				method: 'POST',
				body,
			}),
		}),
		getProcedureList: builder.query<IApiResponse<IPaginate<IProcedureList[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/procedure?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		searchICD: builder.query<
			IApiResponse<IPaginate<IDiagnosisSearch[]>>,
			{
				keyword: string;
				type: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ keyword = '', type, pagination: { page = 1, limit = 10 } }) =>
				`/icd-search/?keyword=${keyword}&type=${type}&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureList', id: 'LIST' }],
		}),
		getProcedureForAnId: builder.query<IApiResponse<IProcedureList>, string>({
			query: (id) => `/procedure/${id}`,
			//   keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'ProcedureList', id: arg }],
		}),
		addProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query: (body) => ({
				url: `/procedure`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
		setDefaultTest: builder.mutation<any, Partial<IProcedureList>>({
			query(body) {
				return {
					url: `/procedure/setup`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
		searchTest: builder.query<
			IApiResponse<IPaginate<IProcedureList[]>>,
			{
				name?: string;
				category?: string;
				categoryId?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ name, category, categoryId, pagination: { page = 1, limit = 10 } }) =>
				`/procedure/filter?page=${page}&limit=${limit}${name && `&name=${name}`}`,
			providesTags: [{ type: 'ProcedureList' }],
		}),
		updateProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query(data) {
				return {
					url: `/procedure/${data.id}`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'ProcedureList', id: arg.id }, 'ProcedureList'],
		}),
		generateProcedureList: builder.mutation({
			query() {
				return {
					url: '/procedure/generate',
					method: 'POST',
				};
			},
			invalidatesTags: ['ProcedureList'],
		}),
		deleteProcedureList: builder.mutation<IProcedureList, Partial<IProcedureList>>({
			query: ({ id }) => ({
				url: `/procedure/${id}`,
				method: 'DELETE',
				body: id,
			}),
			invalidatesTags: ['ProcedureList', 'ProcedureList'],
		}),
	}),
});

export const {
	useUploadProcedureItemsFileMutation,
	useGetProcedureListQuery,
	useGetProcedureForAnIdQuery,
	useSearchICDQuery,
	useAddProcedureListMutation,
	useUpdateProcedureListMutation,
	useSetDefaultTestMutation,
	useGenerateProcedureListMutation,
	useDeleteProcedureListMutation,
	usePrefetch,
} = procedureApi;
