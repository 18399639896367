import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  IAdmissionObject,
  RoomPatient,
  RoomPatientType,
} from "../../../interfaces/admission";
import { Room } from "../../../interfaces/block";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { Therapeutic } from "../../../interfaces/medication";
import { IVitals } from "../../../interfaces/vitals";
import {
  WardRound,
  WardRoundFormValues,
} from "../../../pages/HospitalAdmin/admissions/CreateWardRoundModal";
import { baseQuery } from "../common";

export const admissionApi = createApi({
  reducerPath: "admission",
  baseQuery: baseQuery,
  tagTypes: ["Admission", "Admissions"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAdmissions: builder.query<
      IApiResponse<IPaginate<Room[]>>,
      {
        pagination: { page: number; limit: number };
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/rooms?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),

    getPatientAdmission: builder.query<IApiResponse<RoomPatient>, string>({
      query: (id) => `/patient-admissions/${id}`,
      keepUnusedDataFor: 300,
      providesTags: (result, error, arg) => [{ type: "Admission", id: arg }],
    }),

    getAdmissionsPatientModule: builder.query<
      IApiResponse<IPaginate<RoomPatientType>>,
      {
        pagination: { page: number; limit: number };
        patientId: string;
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, patientId }) =>
        `/patient-admissions?page=${page}&limit=${limit}&patientId=${patientId}`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),

    getPatientAdmissionVitals: builder.query<IApiResponse<IVitals[]>, string>({
      query: (id) => `/patient-admissions/${id}/vitals`,
      providesTags: (result, error, arg) => [{ type: "Admission", id: arg }],
    }),

    getPatientAdmissionTherapeutic: builder.query<
      IApiResponse<Therapeutic[]>,
      string
    >({
      query: (id) => `/patient-admissions/${id}/therapeutic-administrations`,
      providesTags: (result, error, arg) => [{ type: "Admission", id: arg }],
    }),

    getPatientAdmissionWardRound: builder.query<
      IApiResponse<any[]>,
      string
    >({
      query: (id) => `/patient-admissions/${id}/ward-round`,
      providesTags: (result, error, arg) => [{ type: "Admission", id: arg }],
    }),

    getAdmittedRoomPatients: builder.query<
      IApiResponse<IPaginate<RoomPatientType>>,
      {
        roomId: string;
        pagination: { page: number; limit: number };
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: (
        {
          roomId,
          pagination: { page = 1, limit = 10 },
          searchName,
          startDate,
          endDate,
        },
      ) =>
        `/patient-admissions?roomId=${roomId}&filterOperator=${`and`}&status[]=admitted&page=${page}&limit=${limit}${
          searchName && `&patientName=${searchName}`
        }${startDate && `&startDate=${startDate}`}${
          endDate && `&endDate=${endDate}`
        }`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),

    getDischargedAndReleasedPatients: builder.query<
      IApiResponse<IPaginate<RoomPatientType>>,
      {
        searchName?: string;
        startDateAd?: string;
        endDateAd?: string;
        startDateDis?: string;
        endDateDis?: string;
        pagination: { page: number; limit: number };
      }
    >({
      query: (
        {
          pagination: { page = 1, limit = 10 },
          searchName,
          startDateAd,
          endDateAd,
          startDateDis,
          endDateDis,
        },
      ) =>
        `/patient-admissions?filterOperator=and&status[]=discharged&status[]=released&page=${page}&limit=${limit}${
          searchName && `&patientId=${searchName}`
        }${startDateAd && `&startAdmissionDate=${startDateAd}`}${
          endDateAd && `&endAdmissionDate=${endDateAd}`
        }${startDateDis && `&startDate=${startDateDis}`}${
          endDateDis && `&endDate=${endDateDis}`
        }`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),

    getAdmittedPatients: builder.query<
      IApiResponse<IPaginate<RoomPatientType>>,
      {
        searchName?: string;
        startDate?: string;
        endDate?: string;
        //startDateDis?: string;
        //endDateDis?: string;
        pagination: { page: number; limit: number };
      }
    >({
      query: (
        {
          pagination: { page = 1, limit = 10 },
          searchName,
          startDate,
          endDate,
          //startDateDis,
          //endDateDis,
        },
      ) =>
        `/patient-admissions?filterOperator=and&status[]=admitted&page=${page}&limit=${limit}${
          searchName && `&patientId=${searchName}`
        }${startDate && `&startDate=${startDate}`}${
          endDate && `&endDate=${endDate}`
        }`,
      providesTags: [{ type: "Admissions", id: "LIST" }],
    }),

    dischargedPatient: builder.mutation<
      any,
      { id: string; releasedBy: string; releasedByName: string }
    >({
      query(data) {
        return {
          url: `/patient-admissions/${data.id}/discharge`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Admissions", id: "LIST" },
        { type: "Admission", id },
      ],
    }),

    releasedDischargedPatient: builder.mutation<
      any,
      { id: string; releasedBy: string; releasedByName: string }
    >({
      query(data) {
        return {
          url: `/patient-admissions/${data.id}/release`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Admissions", id: "LIST" },
        { type: "Admission", id },
      ],
    }),

    createWardRound: builder.mutation<any, WardRound>({
      query(data) {
        return {
          url: `/ward-round`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, { admissionId }) => [
        { type: "Admission", id: admissionId },
        { type: "Admissions", id: "LIST" },
      ],
    }),

    addBloodRequestToWardRound: builder.mutation<
      any,
      {
        id: string;
        requests: Array<{
          civisHospitalId: string;
          creatorId: string;
          creatorName: string;
          createdAt: string;
          bloodGroup: string;
          requestedQuantity: number;
          priority: string;
          requesterId: string;
          requesterFullname: string;
          requesterType: string;
        }>;
      }
    >({
      query(data) {
        return {
          url: `/ward-round/${data.id}/blood-request`,
          method: "PUT",
          body: data.requests,
        };
      },
      invalidatesTags: (result, error) => [
        { type: "Admissions", id: "LIST" },
      ],
    }),

    addLabRequestToWardRound: builder.mutation<
      any,
      {
        id: string;
        requestCreatorId: string;
        requests: Array<{
          selectedTest: string;
          comment: {
            description: string;
          };
        }>;
      }
    >({
      query(data) {
        return {
          url: `/ward-round/${data.id}/lab-request`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error) => [
        { type: "Admissions", id: "LIST" },
      ],
    }),

    addRadRequestToWardRound: builder.mutation<
      any,
      {
        id: string;
        requestCreatorId: string;
        requests: Array<{
          selectedTest: string;
          comment: {
            description: string;
          };
        }>;
      }
    >({
      query(data) {
        return {
          url: `/ward-round/${data.id}/rad-request`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error) => [
        { type: "Admissions", id: "LIST" },
      ],
    }),

    addTreatmentPlanToWardRound: builder.mutation<
      any,
      {
        id: string;
        requests: Array<{
          patientId: string;
          productId: string;
          quantity: number;
          prescriptionFor: string;
          description: string;
          creatorId: string;
          creatorName: string;
          comment: string;
          dosage: number;
          dosageUOM: string;
          frequency: string;
          startDate: string;
          duration: number;
          durationUOM: string;
          administrationRoute: string;
          autoCreate: boolean;
        }>;
      }
    >({
      query(data) {
        return {
          url: `/ward-round/${data.id}/treatment-plan`,
          method: "PUT",
          body: data.requests,
        };
      },
      invalidatesTags: (result, error) => [
        { type: "Admissions", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAdmissionsQuery,
  useGetPatientAdmissionQuery,
  useGetAdmissionsPatientModuleQuery,
  useGetPatientAdmissionVitalsQuery,
  useGetPatientAdmissionTherapeuticQuery,
  useGetPatientAdmissionWardRoundQuery,
  useGetAdmittedRoomPatientsQuery,
  useGetAdmittedPatientsQuery,
  useGetDischargedAndReleasedPatientsQuery,
  useDischargedPatientMutation,
  useReleasedDischargedPatientMutation,
  useCreateWardRoundMutation,
  useAddBloodRequestToWardRoundMutation,
  useAddLabRequestToWardRoundMutation,
  useAddRadRequestToWardRoundMutation,
  useAddTreatmentPlanToWardRoundMutation,
} = admissionApi;
