export interface Guide {
	name: string;
	path?: string;
	icon?: string;
}

const Guides: Array<Guide> = [
	{
		name: 'Dashboard',
		path: 'dashboard',
		icon: 'bi bi-grid',
	},
	{
		name: 'Appointments',
		path: 'appointments',
		icon: 'bi bi-calendar3',
	},
	{
		name: 'Patients',
		path: 'patients',
		icon: 'fas fa-users',
	},
	{
		name: 'Inventory',
		path: 'inventory',
		icon: 'fas fa-boxes',
	},
	{
		name: 'Vendor',
		path: 'vendor',
		icon: 'fa fa-cart-arrow-down',
	},
	{
		name: 'Medication',
		path: 'medications',
		icon: 'fas fa-capsules',
	},
	{
		name: 'Labs',
		path: 'labs',
		icon: 'fas fa-microscope',
	},
	{
		name: 'Blood Management',
		path: 'blood-management',
		icon: 'fa fa-tint ',
	},
	{
		name: 'Radiology',
		path: 'radiology',
		icon: 'fa fa-user-md',
	},
	{
		name: 'Billing & Collection',
		path: 'billing',
		icon: 'fas fa-money-bill',
	},
	{
		name: 'Admissions',
		path: 'admissions',
		icon: 'fas fa-hotel',
	},
	{
		name: 'Report',
		path: 'report',
		icon: 'bi bi-file-text-fill',
	},
	{
		name: 'Administrative',
		path: 'admin',
		icon: 'fas fa-user-shield',
	},
	{
		name: 'Settings',
		path: 'settings',
		icon: 'fas fa-cog',
	},
	{
		name: 'Queue Management',
		path: 'queue-management',
		icon: 'fa fa-user-plus',
	},
	{
		name: 'Procedure',
		path: 'procedure',
		icon: 'fas fa-procedures',
	},
];

export default Guides;
