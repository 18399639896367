import { createApi } from '@reduxjs/toolkit/query/react';
import { IProcedureCategory } from '../../../interfaces/procedure';
import { IApiResponse, IPaginate, NoPaginate } from '../../../interfaces/common';
import { baseQuery } from '../common';

export const procedureCategoryApi = createApi({
	reducerPath: 'procedureCategory',
	baseQuery: baseQuery,
	tagTypes: ['ProcedureCategories', 'ProcedureCategory'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getProcedureCat: builder.query<IApiResponse<IPaginate<IProcedureCategory[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `procedure-category?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ProcedureCategories', id: 'LIST' }],
		}),
		getAllProcedureCat: builder.query<IApiResponse<NoPaginate<IProcedureCategory[]>>, void>({
			query: () => `procedure-category?limit=${99999}`,
			providesTags: [{ type: 'ProcedureCategories', id: 'LIST' }],
		}),
		getProcedureCatForAnId: builder.query<IApiResponse<IProcedureCategory>, string>({
			query: (id) => `procedure-category/${id}`,
			providesTags: (result, error, arg) => [{ type: 'ProcedureCategory', id: arg }],
		}),
		addProcedureCat: builder.mutation<IProcedureCategory, Partial<IProcedureCategory>>({
			query: (body) => ({
				url: `procedure-category`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ProcedureCategories', 'ProcedureCategory'],
		}),
		updateProcedureCat: builder.mutation<IProcedureCategory, Partial<IProcedureCategory>>({
			query(data) {
				return {
					url: `procedure-category/${data.id}`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'ProcedureCategory', id: arg.id }, 'ProcedureCategories'],
		}),
		deleteProcedureCat: builder.mutation<IProcedureCategory, Partial<IProcedureCategory>>({
			query: ({ id }) => ({
				url: `procedure-category/${id}`,
				method: 'DELETE',
				body: id,
			}),
			invalidatesTags: ['ProcedureCategories', 'ProcedureCategory'],
		}),
	}),
});

export const {
	useGetProcedureCatQuery,
	useGetAllProcedureCatQuery,
	useAddProcedureCatMutation,
	useUpdateProcedureCatMutation,
	useDeleteProcedureCatMutation,
} = procedureCategoryApi;
